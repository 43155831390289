@import "../../global.scss";
.sidebar{
    position: fixed;
    right:-100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 1s ease;
    backdrop-filter: blur(5px);
    z-index: 999;
    position: fixed;
    top: 72px;
    background-color: #666d8d;
    border-radius: 8px;

    @include mobile_potrait{
        right:-100%;
    }
    
    &.active{
        right: 18px;
    }
    .menu_list{
        display: flex;
        flex-direction: column;
        grid-area: auto;
        gap: 16px;
        box-sizing: border-box;
        padding: 32px 56px;
        a{
            color: #000;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .btn_div{
            .ju367v1n{
                flex-direction: column !important;
            }
        }
        
    }
} 