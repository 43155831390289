@import "../../../global.scss";
.about{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 6rem 46px;
    min-height: calc(100vh);
    @include mobile_potrait{
        padding: 6rem 10px;
    }

    .content{
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
        }
        

        .wrapper{
            width: 100%;
            max-width: 1320px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                width: 100%;
                flex-direction: column;
            }
            .left{
                width: 50%;
                @include mobile_potrait{
                    display: none;
                }
            }
            .right{
                width: 50%;
                background: linear-gradient(180deg,rgba(255,255,255,.09) 0,rgba(255,255,255,0) 100%);
                display: flex;
                flex-direction: column;
                border-radius: 1rem;
                padding: 3rem;
                box-sizing: border-box;
                @include mobile_potrait{
                    width: 100%;
                }
            }
            
            h2{
                font-size: 1.5rem;
                color: #fff;
                width: 100%;
                margin-bottom: 16px;
                font-family: 'Russo One', sans-serif;
                font-weight: normal;
                margin-bottom: 0.5rem;
                @include mobile_potrait{
                    font-size: 24px;
                }
                
            }
            p{
                line-height: 1.8;
                margin-bottom: 1.25rem;
                font-size: 15px;
                color: #fff;
            }
            a{
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                color: #110d24;
                background-color: #9dd945;
                border: 2px solid #9dd945;
                text-decoration: none;
                border-radius: 0.25rem;
                padding: 1rem 2rem;
                font-size: 16px;
                font-weight: 700;
                transition: all 0.3s ease;
                &:hover{
                    background-color: #8acc2a;
                }

            }

        }

    }
    .bg{
        position: absolute;
        opacity: 0.15;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
    
}
.social_links_home{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: auto;
    gap : 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    a{
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover{
            color: #7ccbe1;
        }
    }
    span{
        color: #fff;
    }

}
