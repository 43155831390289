@import "../../global.scss";
.footer{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8rem 32px;
    padding-bottom: 8rem;
    border-top: 1px #222222 solid;
    @include mobile_potrait{
        padding: 3rem 32px;
    }
    .footerContent{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        z-index: 1;
        max-width: 1240px;
        @include mobile_potrait{
            flex-direction: column;
        }
        
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 2rem;
            grid-area: auto;
            gap: 24px;
            @include mobile_potrait{
            }
            p{
                line-height: 1.8;
                font-size: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                grid-area: auto;
                gap : 10px;
                @include mobile_potrait{
                    max-width: 240px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }
            }
            .logo{
                width: 200px;
            }
            .desc{
                color: #65707a;
                background-color: #0b0b0c;
                padding: 1rem;
                border: 1px solid #1a1a1e;
                border-radius: .25rem;
                grid-area: auto;
                gap : 16px;
                display: flex;
                font-size: 18px;
                @include mobile_potrait{
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    max-width: 240px;
                    box-sizing: border-box;
                    display: block;
                    text-align: center;

                }
                span{
                    color: #65707a;
                    @include mobile_potrait{
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
            
        }
        
        span{
            text-align: center;
            color: #fff;
            font-size: 18px;
            
            a{
                color: #FF0099;
                text-decoration: none;
                transition: all 0.3s ease;
                &:hover{
                    color: #ff3db2;
                }
            }
        }
        
    }
    
}