@import "../../../global.scss";
.home{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 6rem 46px;
    min-height: calc(100vh);
    
    @include mobile_potrait{
        padding: 4rem 0px;

    }

    .content{
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
        }
        
        

        .wrapper{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include mobile_potrait{
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: auto;
                margin-top: 5rem;
            }
            .logo{
                margin-bottom: 3rem;
                img{
                    width: 340px;
                    height: 340px;
                    background-color: #000;
                    border-radius: 50%;
                }
            }
            h1{
                font-size: 4.25rem;
                font-weight: 900;
                line-height: 1.3;
                font-family: 'Russo One', sans-serif;
                color: #7ccbe1;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 3rem;
                }
            }
            h2{
                font-size: 32px;
                color: #fff;
                width: 100%;
                margin-bottom: 16px;
                text-align: center;
                font-family: 'Russo One', sans-serif;
                font-weight: normal;
                text-transform: uppercase;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 1.6rem;
                }
                span{
                    color: #6c757d;
                }
            }
            h3{
                color: #7ccbe1;
                font-weight: 400;
                font-size: 1.25rem;
                font-family: 'Russo One', sans-serif;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    text-align: center;
                }
            }
            a{
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                color: #110d24;
                background-color: #9dd945;
                border: 2px solid #9dd945;
                text-decoration: none;
                border-radius: 0.25rem;
                padding: 1rem 2rem;
                font-size: 16px;
                font-weight: 700;
                transition: all 0.3s ease;
                &:hover{
                    background-color: #8acc2a;
                }

            }

        }

    }
    .bg{
        position: absolute;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
    
}
.social_links_home{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: auto;
    gap : 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    a{
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover{
            color: #7ccbe1;
        }
    }
    span{
        color: #fff;
    }

}

