@import "../../global.scss";
.navbar-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  backdrop-filter: blur(10px);
  .header_content {
    padding: 13px 0px;
    background: #00000000;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include mobile_potrait{
      padding: 13px 16px;
    }
    .wrapper{
      width: 100%;
      max-width: 1320px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav_list{
        display: flex;
        padding: 0;
        list-style: none;
        align-items: center;
        justify-content: center;
        grid-area: auto;
        gap : 36px;
        @include mobile_potrait{
          display: none;
        }
        li{
          a{
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            text-decoration: none;
            transition: all 0.3s ease;
            text-transform: uppercase;
            &:hover{
              color: #7ccbe1;
            }
          }
        }
      }
    }
  }
  

  .logo {
    display: flex;
    align-items: center;
    a{
      display: flex;
      align-items: center;
      grid-area: auto;
      gap : 8px;
      img {
        display: block;
        height: 4.5rem;
        max-width: 100%;
        width: auto;  
        border: none;
        @include mobile_potrait{
          height: 72px;
          display: block;
        }
      }
      h1{
        font-weight: normal;
        color: #7ccbe1;
        font-size: 16px;
        font-weight: 900;
        font-family: 'Russo One', sans-serif;
        @include mobile_potrait{
          display: none;
        }
      }
      
    }

    
  }

  .btn_div{
    @include mobile_potrait{
      // padding-right: 32px;
    }
    button {
      // height: 100%;
      // width: 100%;
      color: #110d24;
      background-color: #9dd945;
      position: relative;
      overflow: hidden;
      font-weight: normal;
      border-radius: 5px;
      @include mobile_potrait{
        font-size: 10px;
      }
      div{
        color: #110d24;
      }
      .ju367v6e {
        border-color: #70a71f;
        border-radius: 5px;
      }
      .ju367v8q{
        @include mobile_potrait{
          display: none;
        }
      }
      &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .showMenuBtn{
    display: none;
    @include mobile_potrait{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 16px;
      top: 32px;
      background-color: #666d8d;
      color: rgba(0,0,0,.55);
      border: none;
      padding: .25rem .75rem;
      font-size: 1.5rem;
      width: 56px;
      height: 40px;
      box-sizing: border-box;
      font-weight: lighter;
      border-radius: 5px;
    }
  }
  
}