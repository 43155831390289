@import "global.scss";

.sections{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #010414;
    z-index: 1;
    overflow: hidden;
    @include mobile_potrait{
        // height: 100%;
    }
    
}
html{
    @include mobile_potrait{
        height: 100%;
    }
}
body{
    @include mobile_potrait{
        height: 100%;
    }
}
#root{
    @include mobile_potrait{
        height: 100%;
    }
    
}
div[data-rk]{
    @include mobile_potrait{
        height: 100%;
    }
}

.myToast{
    left: calc(50% - 75px) !important;
    // width: 400px !important;
    max-width: 100% !important;
    background: linear-gradient(92.73deg, #010414 0.61%, #010414 99.34%) !important;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    >div{
        color: #fff;
        width: fit-content !important;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row !important;
        font-size: 12px;
    }
    
}
.notification_div{
    font-size: 12px;
    color: rgb(255, 255, 255);
    background: #15ab70;
    width: calc(100% - 40px);
    border-width: 0;
    left: 0;
    display: block;
    border-radius: 5px;
    box-sizing: border-box;
    opacity: 1;
    position: fixed;
    z-index: 9999;
    border-width: 0;
    margin: 1.667em;
    bottom: 0;
    padding: 1rem 2rem;
    @include mobile_potrait{
        width: 100%;
        margin: 0;
    }
    .content{
        width: 100%;
        display: flex;
        align-items: center;
        @include mobile_potrait{
            width: 100%;
            flex-direction: column;
        }
        .left{
            width: 60%;
            display: flex;
            flex-direction: column;
            @include mobile_potrait{
                width: 100%;
                margin-bottom: 1rem;
                align-items: center;
                justify-content: center;
            }
            p{
                font-size: 13px;
                span{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            a{
                margin-bottom: 1rem;
                img{
                    height: 24px;

                }
            }

        }
        .right{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-area: auto;
            gap:16px;
            @include mobile_potrait{
                width: 100%;
                flex-direction: column-reverse;
                gap: 8px;
            }
            button{
                background-color: #fff;
                width: 154px;
                box-sizing: border-box;
                padding: 8px;
                border: none;
                border-radius: 5px;
                transition: all 0.3s ease;
                text-align: center;
                cursor: pointer;
                @include mobile_potrait{
                    width: 100%;
                }

            }
            .outline{
                border: 1px #fff solid;
                background-color: #ffffff00;
                color: #fff;
            }

        }
    }
}
.my_modal{
    width: 940px;
    background-color: #fff;
    padding: 2rem;
    padding-top: 3rem;
    box-sizing: border-box;
    @include mobile_potrait{
        width: 90vw;
    }
    .content{
        width: 100%;
        height: 70vh;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            
            @media screen and (max-width: 648px) and (orientation: portrait) {
              height: 3px;
            }
          }
    }
    
    iframe{
        width: 100%;
        // overflow: scl; 
        height: 3538px;
    }
}
.my_modal_1{
    width: 645px;
    background: #15ab70;
    padding: 2rem;
    padding-top: 3rem;
    box-sizing: border-box;
    @include mobile_potrait{
        width: 90vw;
    }
    .content{
        width: 100%;
        height: 70vh;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            
            @media screen and (max-width: 648px) and (orientation: portrait) {
              height: 3px;
            }
        }
        .row{
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            border-bottom: 1px #ffffff88 solid;
        }
        h3{
            font-size: 21px;
            font-weight: 500;
            line-height: 1.24;
            color: #fff;
            margin: 0;
            margin-bottom: 21px;
        }
        p{
            margin: 0;
            padding: 0;
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 1.5;
            margin-left: 27px;
            color: #fff;
        }
        .check_div{
            display: flex;
            align-items: center;
            grid-area: auto;
            gap: 10px;
            .detail_btn{
                font-size: 13px;
                cursor: pointer;
                color: #fff;
                text-decoration: underline;
            }
        }
        ul{
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            li{
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px #fff solid;
            }
        }
        .btns{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-area: auto;
            gap : 16px;
            button{
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
                transition: all 0.3s ease;
                &:hover{
                    opacity: 0.8;
                }

            }

        }
    }
    
}
.button{
    padding : 10px 20px;
    border: none;
    border-radius: 10px;
    
    background:linear-gradient(to top, #3e3d8a, #afafd6);
    position: relative;
    overflow: hidden;
    font-weight: normal;
    
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    @include mobile_potrait{
        font-size: 0.8rem;
        border-radius: 5px;
    }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        
      }
    &:disabled{
        background: #00000000 !important;
        border-color: #626262 !important;
        p{
            color: #626262 !important;
        }
    }
    &:hover{
        opacity: 0.8;
    }
    
    p{
        z-index: 1;
        color: #fff;
        line-height: 1;
        font-size: 14px;
        transition: all 0.3s ease;
        @include mobile_potrait{
            font-size: 0.8rem;
        }

    }
}
._9pm4ki3{
    @include mobile_potrait{
        padding: 0 !important;
        top : -200px !important;
        // transform: translateY(-50%);
    }
    ._1ckjpok1{
        @include mobile_potrait{
            max-width: 95vw !important;
            border-radius: 12px !important;
        }
    }
    ._1ckjpok7{
        @include mobile_potrait{
            padding: 0 !important;
            top : 0 !important;
        }
    }
    .ju367v7r{
        @include mobile_potrait{
            padding-right: 8px !important;

        }
    }
    .ju367v76{
        @include mobile_potrait{
            padding-left: 8px !important;
        }
    }
}
.check-account-container{
        display: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    
    @media screen and (max-width: 648px) and (orientation: portrait) {
      height: 3px;
    }
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
    padding : 5px,
  }
  
  ::-webkit-scrollbar-track {
    background: #0c000c88;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  
  }