@import "../../../global.scss";
.tokenomics{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 3rem 46px;
    
    @include mobile_potrait{
        padding: 3rem 10px;

    }

    .content{
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
        }
        
        

        .wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            max-width: 1320px;
            @include mobile_potrait{
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: auto;
            }
            
            h2{
                display: inline-block;
                font-size: 2.8rem;
                color: #fff;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: 2rem;
                letter-spacing: 3px;
                font-family: 'Russo One', sans-serif;
                @include mobile_potrait{
                }
                span{
                    color: #9dd945;
                }
            }
            h3{
                color: #fff;
                font-weight: 400;
                font-size: 1.5rem;
                font-family: 'Russo One', sans-serif;
                line-height: 1.2;
            }
            p{
                font-size: 20px;
                color: #fff;
                line-height: 1.8;
                margin-bottom: 0.5rem;
                span{
                    font-size: 18px;
                    color: #9dd945;
                    font-weight: 600;
                    font-family: CONSOLAB;
                }
            }
            ul{
                display: flex;
                flex-direction: row;
                grid-area: auto;
                gap : 20px;
                width: 100%;
                padding: 0;
                @include mobile_potrait{
                    flex-wrap: wrap;
                    gap : 10px;
                    justify-content: space-between;
                }
                li{
                    flex: 1;
                    padding: 1rem;
                    border-radius: .5rem;
                    border: 1px solid;
                    background: #181b29;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    @include mobile_potrait{
                        width: calc(50% - 10px);
                        box-sizing: border-box;
                        flex: none;
                    }
                }
            }
        }

    }
    
    
}
