@import "../../../global.scss";
.how_participate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 6rem 46px;
    min-height: calc(100vh);
    
    @include mobile_potrait{
        padding: 6rem 10px;
    }

    .content{
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
        }
        
        

        .wrapper{
            width: 100%;
            display: flex;
            // align-items: center;
            justify-content: space-between;
            max-width: 1320px;
            @include mobile_potrait{
                margin-bottom: 3rem;
                flex-direction: column;
            }
            .left{
                display: flex;
                flex-direction: column;
                width: 70%;
                @include mobile_potrait{
                    width: 100%;
                }
            }
            .right{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 30%;
                @include mobile_potrait{
                    width: 100%;
                }
                
            }
            .col{
                width: 30%;
                display: flex;
                flex-direction: column;
                @include mobile_potrait{
                    width: 100%;
                }
            }
            .w_full{
                width: 100%;
                p{
                    width: 50%;
                    @include mobile_potrait{
                        width: 100%;
                    }
                }
            }
            h1{
                font-size: 6rem;
                font-weight: 900;
                line-height: 1.3;
                font-family: 'Russo One', sans-serif;
                color: #11251e;
                line-height: 1.2;
                @include mobile_potrait{
                }
            }
            h5{
                color: #9dd945;
                margin-bottom: 1rem;
                margin-top: 1rem;
                font-size: 18px;

            }
            h2{
                display: inline-block;
                font-size: 2.8rem;
                color: #fff;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: .5rem;
                letter-spacing: 3px;
                font-family: 'Russo One', sans-serif;
                @include mobile_potrait{
                    margin-bottom: 0;
                }
                span{
                    color: #9dd945;
                }
            }
            h3{
                color: #fff;
                font-weight: 400;
                font-size: 1.5rem;
                font-family: 'Russo One', sans-serif;
                line-height: 1.2;
                margin-bottom: 0.5rem;
            }
            p{
                font-size: 20px;
                color: #fff;
                line-height: 1.8;
                margin-bottom: 1.25rem;
                span{
                    font-size: 18px;
                    color: #9dd945;
                    font-weight: 600;
                    font-family: CONSOLAB;
                }
            }
            ul{
                display: flex;
                flex-direction: column;
                margin-bottom: 3rem;
                li{
                    font-size: 16px;
                    color: #fff;
                    line-height: 1.8;
                }
            }
            a{
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                color: #9dd945;
                background-color: #1d1e1c;
                border: 2px solid #1d1e1c;
                text-decoration: none;
                border-radius: 0.25rem;
                padding: 1rem 2rem;
                font-size: 16px;
                font-weight: 700;
                transition: all 0.3s ease;
                // line-height: 1.5;
                &:hover{
                    background-color: #9dd945;
                    border-color: #9dd945;
                    color: #1d1e1c;
                }

            }
            &.mb-3{
                margin-bottom: 3rem;
            }
            .btns{
                display: flex;
                grid-area: auto;
                gap: 24px;
            }
            

        }

    }
    .bg{
        position: absolute;
        opacity: 0.2;
        width: 100%;
        // height: 100%;
        object-fit: cover;
        left: 0;
        bottom: 0;
        @include mobile_potrait{
            top: auto;
            bottom: auto;
        }
    }
    
}
