@import "../../../global.scss";
.quick_start{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 6rem 46px;
    min-height: calc(100vh);
    
    @include mobile_potrait{
        padding: 6rem 10px;
    }

    .content{
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
        }

        .wrapper{
            width: 100%;
            max-width: 1320px;
            display: flex;
            flex-direction: column;
            @include mobile_potrait{
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: auto;
            }
           
            h2{
                display: inline-block;
                font-size: 2.8rem;
                color: #fff;
                font-weight: 600;
                line-height: 1.3;
                margin-bottom: 2rem;
                letter-spacing: 3px;
                font-family: 'Russo One', sans-serif;
                @include mobile_potrait{
                }
                span{
                    color: #9dd945;
                }
            }
            h3{
                color: #fff;
                font-weight: 400;
                font-size: 2rem;
                font-family: 'Russo One', sans-serif;
                line-height: 1.2;
            }
            p{
                line-height: 1.8;
                margin-bottom: 1.25rem;
                font-size: 20px;
                color: #fff;
            }
            ul{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 2rem 3rem;
                border-radius: .5rem;
                box-sizing: border-box;
                background: linear-gradient(180deg,rgba(255,255,255,.09) 0,rgba(255,255,255,0) 100%);
                list-style: none;
                @include mobile_potrait{
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                li{
                    display: flex;
                    grid-area: auto;
                    gap : 20px;
                    margin-bottom: 2.5rem;
                    &.border_bottom{
                        border-bottom: 1px #243630 solid;
                        padding-bottom: 1rem;
                    }
                    
                    .number{
                        font-size: 2.5rem;
                        width: 64px;
                        height: 64px;
                        background-color: #000;
                        border-radius: 50%;
                        color: #fff;
                        font-family: 'Russo One', sans-serif;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                    }
                    .line{
                        width: 75px;
                        height: 2px;
                        background: #9dd945;
                        margin-top: 1.5rem;
                        @include mobile_potrait{
                            display: none;
                        }
                    }
                    .text{
                        flex: 1;
                    }
                }
            }

        }

    }
    
}
