.loading_page {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  .logoIcon {
    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 1;
    position: absolute;
    width: 200px;
    @media screen and (max-width: 768px) and (orientation: portrait) {
    }
    @media screen and (max-height: 768px) and (orientation: landscape) {
      height: 70px;
    }
    @keyframes loading {
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
.activeLoading {
  opacity: 1;
  z-index: 9999;
}
