@import "../../global.scss";
.customModal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left : 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 999;
    display: none;
    opacity: 1;
    backdrop-filter: blur(5px);
    &.active{
        display: flex;
    }
    .model_content{
      z-index: 5;
      width: fit-content;
      
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
      opacity: 1;
      border-radius: 10px;
      overflow: hidden;
      
      @include mobile_potrait{
      }

      .modal_header{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          z-index: 2;
          top: 0;
          height: 3rem;
          @include mobile_potrait{
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
          }

          .modal_title{
              font-size: 22px;
              padding-left: 20px;
              white-space: nowrap;
              letter-spacing: -1px;
              color: #3e3d8a;
              @include mobile_potrait{
                font-size: 18px;
            }
          }
          .close_btn{
              font-size: 24px;
              font-weight: 600;
              border: none;
              background-color: #00000000;
              border-radius: 16px;
              cursor: pointer;
              color: #3e3d8a;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s ease;
              &:hover{
                transform: rotate(90deg);
              }
          }
      }
      
      .modal_wrapper{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        z-index: 1;
        grid-area: auto;
        
      }
    }
}
